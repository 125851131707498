/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Menu, Text, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Title, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kombinovaná topidla"} description={"Informace o kombinovaných topidlech"}>
        <Column className="pt--10" name={"speciality-oddelovaci-cara"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--l8w" name={"68nisry9r0n"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingRight":1011}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/6aad81a433d14d4e945f8b2ae2142da8_s=860x_.png"} url={"https://www.facebook.com/profile.php?id=61553456535181"} use={"url"} href={"https://www.facebook.com/profile.php?id=61553456535181"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/6aad81a433d14d4e945f8b2ae2142da8_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/6aad81a433d14d4e945f8b2ae2142da8_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/6aad81a433d14d4e945f8b2ae2142da8_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/6aad81a433d14d4e945f8b2ae2142da8_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/6aad81a433d14d4e945f8b2ae2142da8_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/aafbf232815746718387a65e546b35f3_s=860x_.png"} url={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} use={"url"} href={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/aafbf232815746718387a65e546b35f3_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/aafbf232815746718387a65e546b35f3_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/aafbf232815746718387a65e546b35f3_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/aafbf232815746718387a65e546b35f3_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/aafbf232815746718387a65e546b35f3_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu" menu={true} name={"speciality-hlavicka"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"KAMNÁŘSTVÍ NA ROZKOŠI"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/nabizime"} style={{"maxWidth":125}} target={""} content={"nabízíme"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/cenik"} style={{"maxWidth":125}} target={""} content={"ceník"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/poradna"} style={{"maxWidth":140}} target={""} content={"poradna"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/galerie"} style={{"maxWidth":140}} target={""} content={"GALERIE"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/aktuality"} style={{"maxWidth":125}} target={""} content={"AKTUALITY"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/o-nas"} style={{"maxWidth":125}} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/kontakt"} style={{"maxWidth":125}} target={""} content={"kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"speciality_cech"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--14" content={"člen Cechu kamnářů České republiky"}>
              </Title>

              <Image className="mt--08" src={"https://cdn.swbpg.com/o/17440/5c02d8768d2342198c276b8957ed27a4.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":60}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"speciality-oddelovac-1"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu" menu={true} name={"speciality-rozcestnik"} style={{"left":"0","zIndex":"2","overflow":"visible","position":"absolute"}}>
          
          <Menu >
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kamna"} target={""} content={"KAMNA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/sporaky"} target={""} content={"SPORÁKY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kombinovana_topidla"} target={""} content={"KOMBINOVANÁ TOPIDLA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/krby"} target={""} content={"KRBY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kominy"} target={""} content={"KOMÍNY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/speciality"} target={""} content={"SPECIALITY"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"pw9x3a3hsz8"}>
        </SeparateLine>


        <SeparateLine className="pb--10" name={"speciality-oddelovac-2"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-1ejrj0c bg--right bg--center --parallax pb--40 pt--40" name={"speciality-vstup"} layout={"l1"} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/33e68f922aff44bcbe58754534d80808_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/33e68f922aff44bcbe58754534d80808_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/33e68f922aff44bcbe58754534d80808_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/33e68f922aff44bcbe58754534d80808_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/33e68f922aff44bcbe58754534d80808_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/33e68f922aff44bcbe58754534d80808_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/33e68f922aff44bcbe58754534d80808_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.15), rgba(17,20,28,0.15)), url(https://cdn.swbpg.com/t/17440/33e68f922aff44bcbe58754534d80808_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72" content={"KOMBINOVANÁ TOPIDLA"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Bez kompromisů můžete se svým topidlem vyřešit několik much jednou ranou. Je jen na vás, jaké funkce spojíme a co všechno bude vaše nové topidlo umět.<br></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mb--10 mt--20" href={"#speciality-popis"} target={null} content={"KOMBINUJ!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"speciality-oddelovac-3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--10 pt--10" name={"speciality-popis"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Co všechno lze?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":334}} content={"S trochou nadsázky může tvrdit, že postavit lze cokoliv. Samozřejmě, vše má svá pravidla a zákonitosti, přes která nejede vlak, ale i tak se nám otevírá široké spektrum možností, co si můžete přát.<br><br>Máme zkušenosti s komibnováním kamen, sporáků, teplovodních výměníků, hypokaustů, absorbérů...&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":334}} content={"Doporučujeme v klidu se posadit u stolu s tužkou v ruce, zasnít se a napsat úplně vše, co byste od svého topidla chtěli. Nebojte se toho. :)<br><br>A společně už vše nakombinujeme, slibujeme.<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2" href={"/poradna"} target={null} content={"TO MĚ ZAJÍMÁ!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"speciality-oddelovac-4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pb--10 pt--10" name={"speicality-galerie"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"GALERIE"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"speciality-galerie-rozcestnik"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="mb--40">
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17440/09c9372a35ff473488a94dd4f76edd24_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17440/09c9372a35ff473488a94dd4f76edd24_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/09c9372a35ff473488a94dd4f76edd24_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/09c9372a35ff473488a94dd4f76edd24_s=860x_.png 860w"} position={{"x":"-65.46153846153844%","y":"-7.923076923076923%"}}>
              </Image>

              <Text className="text-box" content={"3D projekce je tu proto, abyste měli představu, jak to plánovanému topidlu bude slušet u vás doma.\nPodívejte se, jak to vypadá."}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mt--16" href={"/galerie#galerie-3d-nadpis"} target={null} content={"3D PROJEKCE"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mb--40">
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17440/93baad6d086c4f31b9fc4dd7ad2fe866_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17440/93baad6d086c4f31b9fc4dd7ad2fe866_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17440/93baad6d086c4f31b9fc4dd7ad2fe866_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17440/93baad6d086c4f31b9fc4dd7ad2fe866_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17440/93baad6d086c4f31b9fc4dd7ad2fe866_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"Hledáte inspiraci nebo jste jen zvědaví, co všechno jsme stavěli a co nám vyrostlo pod rukama? Nakoukněte do naší galerie realizovaných projektů."}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mt--16" href={"/galerie#galerie_kombinace_nadpis"} target={null} content={"REALIZACE KOMBINACÍ"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mb--40">
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17440/2845f52089c14924abdc4d7adcf1524d_s=660x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17440/2845f52089c14924abdc4d7adcf1524d_s=350x_.JPG 350w, https://cdn.swbpg.com/t/17440/2845f52089c14924abdc4d7adcf1524d_s=660x_.JPG 660w"} position={{"x":"-3.8461538461538463%","y":"-0.7692307692307693%"}}>
              </Image>

              <Text className="text-box" content={"Líbí se vám některá kamna nebo snad sporák?\nRádi byste měli něco podobného i u vás doma?\nSvěřte se svému kamnáři!"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mt--16" href={"/kontakt"} target={null} content={"MŮJ KAMNÁŘ"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"speciality-oddelovac-5"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-xn7sl8 --parallax pb--60 pt--60" name={"speciality-konzultace"} layout={"l1"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pl--40 pr--40 pt--20" style={{"maxWidth":520,"backgroundColor":"rgba(255,255,255,1)"}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"KAMNÁŘSTVÍ <br>NA ROZKOŠI<br>"}>
              </Title>

              <Text className="text-box" content={"Rodinné kamnářství na břehu vodní nádrže Rozkoš. <br>Přijďte si k nám pro návrh šitý vám na míru."}>
              </Text>

              <Button className="btn-box btn-box--filling2" href={"/kontakt"} target={null} content={"KONZULTACE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"speciality-oddelovac-6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu pb--08 pt--20" menu={true} name={"speciality-zapati"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">Daniel vláha +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"kamnářství na rozkoši"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--12 mt--06" content={"člen Cechu kamnářů České republiky"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton className="--hvr2 --filling2">
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/nabizime"} target={null} content={"NABÍZÍME"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/kontakt"} target={null} content={"KONTAKT"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"speciality-mesta"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center ff--3 fs--14 swpf--uppercase" content={"NÁCHOD - ČESKÁ SKALICE - NOVÉ MĚSTO NAD METUJÍ - ČERVENÝ KOSTELEC - BROUMOV - TRUTNOV - DOBRUŠKA - OPOČNO - RYCHNOV NAD KNĚŽNOU - POLICE NAD METUJÍ"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--10" name={"speciality-oddelovac-7"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pt--0" style={{"paddingBottom":2,"backgroundColor":"var(--color-variable-1)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"speciality-paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--11" href={"/zasady-ochrany-osobnich-udaju"} target={null} content={"ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ"}>
              </Button>

              <Text className="text-box ff--3 mt--06" style={{"color":"rgb(0, 0, 0)"}} content={"Přečtěte si podrobné informace o našich Zásadách ochrany osobních údajů před dalším užíváním těchto stránek.<br>Veškeré zde zveřejněné informace a fotografie jsou majetkem Kamnářství na Rozkoši. Jejich šíření je bez souhlasu majitele zakázáno! Děkujeme, že respektujete autorská práva.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}